<template>
  <div class="add_teacher">
    <el-page-header @back="$router.go(-1)" content="添加/修改"> </el-page-header>
    <br />

    <el-form ref="addFromRef" :model="addFrom" :rules="addFromRules" label-width="130px">
      <el-tabs type="border-card">
        <el-tab-pane label="基本信息">
          <el-form-item label="套餐名称:" prop="packName">
            <el-input v-model="addFrom.packName" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="分享标题:" prop="shareTitle">
            <el-input v-model="addFrom.shareTitle" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="原价:" prop="oldFee">
            <el-input v-model="addFrom.oldFee" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="售价:" prop="fee">
            <el-input v-model="addFrom.fee" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="销量:" prop="sellCount">
            <el-input v-model.number="addFrom.sellCount" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="库存:" prop="stock">
            <el-input v-model.number="addFrom.stock" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="限购数量" style="margin-top: 0px">
  <el-input
    @input="onInput"
    v-model="addFrom.quotaCount"
    placeholder="请输入限购数量"
    style="width: 45%"
  ></el-input>
</el-form-item>
<el-form-item label="" style="height: 50px; margin: 0"
  ><span style="color: red"
    >一个客户可购买的数量:0表示不限制,1表示可以购买一次,2表示可以购买两次,以此类推</span
  ></el-form-item
>
          <el-form-item label="是否退订:">
            <el-radio v-model="addFrom.unsubscribe" :label="1">是</el-radio>
            <el-radio v-model="addFrom.unsubscribe" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="是否仅限新用户购买">
        <el-radio v-model="addFrom.isNew" :label="1">是</el-radio>
        <el-radio v-model="addFrom.isNew" :label="0">否</el-radio>
      </el-form-item>
          <el-form-item label="标签:" prop="label">
            <el-tag
              style="margin-right: 5px"
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              style="width: 80px"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput"
              >+ 添加</el-button
            >
          </el-form-item>
          <el-form-item label="销售方式:" prop="sellWay">
            <el-select v-model="addFrom.sellWay" placeholder="请选择">
              <el-option
                v-for="item in sellWayOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所需积分:" prop="integral" v-if="addFrom.sellWay != 1">
            <el-input v-model="addFrom.integral" style="width: 45%"></el-input>
          </el-form-item>

          <el-form-item label="所属分类:" prop="cateId">
            <el-select v-model="addFrom.cateId" placeholder="请选择">
              <el-option
                v-for="item in cateListSun"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效期:" prop="packDate">
            <el-date-picker
              v-model="addFrom.packDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="不可用日期:" prop="noDate">
            <el-input v-model="addFrom.noDate" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="使用时间段:" prop="packTime">
            <el-input v-model="addFrom.packTime" style="width: 45%"></el-input>
          </el-form-item>
          <el-form-item label="使用规则:" prop="rule">
            <el-input
              type="textarea"
              maxlength="300"
              :rows="2"
              v-model="addFrom.rule"
              style="width: 45%"
            ></el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="展示图">
          <el-form-item label="展示图:" prop="showImg">
          <send-image
            type="one"
            :images="addFrom.showImg"
            @addImageStr="addImageStr"
          ></send-image>

          <span style="color: red; margin-left: 20px"
            >每张图片不能超过500K，建议尺寸：168*84px</span
          >
        </el-form-item>
        <el-form-item label="轮播图:" prop="imgUrl">
          <send-image
            type="more"
            :images="addFrom.imgUrl"
            @addImageStrs="addImageStrMore"
          ></send-image>

          <span style="color: red; margin-left: 20px"
            >每张图片不能超过500K，建议尺寸：750*500px</span
          >
        </el-form-item>
        </el-tab-pane>
    
        <el-tab-pane label="详情">
          <el-form-item label="详情:" prop="details">
          <VueUeditorWrap
            v-model="addFrom.details"
            :config="myConfig"
            ref="myTextEditor"
          ></VueUeditorWrap>
        </el-form-item>
        </el-tab-pane>

     
        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import editorConfig from "../../utils/editorConfig.js";
import SendImage from "../../components/sendImage.vue";
import {
  foodPackListAdd,
  foodPackListDetails,
  foodPackageCateListById,
} from "../../api/food.js";

export default {
  components: { VueUeditorWrap, SendImage },
  data() {
    return {
      myConfig: editorConfig,

      addFrom: {
        foodId: 0,
        packId: 0,
        packName: "",
        oldFee: "",
        fee: "",
        sellCount: "",
        packDate: "",
        noDate: "",
        packTime: "",
        cateId: "",
        label: "",
        rule: "",
        showImg: "",
        imgUrl: "",
        details: "",
        stock: "",
        unsubscribe: 0,
        sellWay: "",
        integral: 0,
        shareTitle: "",
        quotaCount:0,
        isNew:0
      },
      cateList: [],
      sellWayOptions: [
        {
          value: 1,
          label: "全金额",
        },
        {
          value: 2,
          label: "全积分",
        },
        {
          value: 3,
          label: "积分+金额",
        },
      ],
      cateListSun: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      addFromRules: {
        packName: [
          { required: true, message: "请填写套餐名称", trigger: ["blur", "change"] },
        ],
        oldFee: [{ required: true, message: "请填写原价", trigger: ["blur", "change"] }],

        fee: [{ required: true, message: "请填写现价", trigger: ["blur", "change"] }],
        sellCount: [
          { required: true, message: "请填写销量", trigger: ["blur", "change"] },
        ],
        packDate: [
          { required: true, message: "请填写有效期", trigger: ["blur", "change"] },
        ],
        noDate: [
          { required: true, message: "请填写不可用日期", trigger: ["blur", "change"] },
        ],
        packTime: [
          { required: true, message: "请填写使用时间段", trigger: ["blur", "change"] },
        ],
        cateId: [
          { required: true, message: "请选择所属分类", trigger: ["blur", "change"] },
        ],
        label: [{ required: true, message: "请填写标签", trigger: ["blur", "change"] }],
        rule: [
          { required: true, message: "请填写使用规则", trigger: ["blur", "change"] },
        ],
        showImg: [
          { required: true, message: "请上传展示图", trigger: ["blur", "change"] },
        ],
        imgUrl: [
          { required: true, message: "请上传轮播图", trigger: ["blur", "change"] },
        ],
        details: [{ required: true, message: "请填写详情", trigger: ["blur", "change"] }],
        stock: [{ required: true, message: "请填写库存", trigger: ["blur", "change"] }],
        sellWay: [
          { required: true, message: "请选择售卖方式", trigger: ["blur", "change"] },
        ],
        integral: [
          { required: true, message: "请填写积分", trigger: ["blur", "change"] },
        ],
        shareTitle: [
          { required: true, message: "请填写分享标题", trigger: ["blur", "change"] },
        ],
      },
    };
  },
  created() {
    this.$route.query.packId && (this.addFrom.packId = this.$route.query.packId);
    this.$route.query.foodId && (this.addFrom.foodId = this.$route.query.foodId);
    if (this.addFrom.packId) {
      this.getDetails();
    }
    this.getFartherCate(this.addFrom.foodId);
  },
  methods: {
    onInput(value) {
  var reg = /^[0-9]*[1-9][0-9]*$/;
  var reg2 = /^-[0-9]*[1-9][0-9]*$/;
  if (!reg.test(value) && !reg2.test(value)) {
    return this.$message({
      type: "error",
      message: "请输入整数!",
    });
  }
},
    getFartherCate(foodId) {
      foodPackageCateListById({ foodId }).then((res) => {
        if (res.data.code == 0) {
          this.cateListSun = res.data.data;
        }
      });
    },
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    addImageStrMore(e) {
      console.log(e, "more");
      this.addFrom.imgUrl = e.join(",");
    },
    getDetails() {
      foodPackListDetails({ packId: this.addFrom.packId }).then((res) => {
        if (res.data.code == 0) {
          //   this.addFrom = res.data.data;
          Object.keys(this.addFrom).forEach((key) => {
            this.addFrom[key] = res.data.data[key];
          });
          let dateArr = [];
          dateArr[0] =
            res.data.data.packDate[0] +
            "-" +
            res.data.data.packDate[1] +
            "-" +
            res.data.data.packDate[2];
          dateArr[1] =
            res.data.data.packDate[3] +
            "-" +
            res.data.data.packDate[4] +
            "-" +
            res.data.data.packDate[5];
          this.addFrom.packDate = dateArr;
          this.addFrom.imgUrl = res.data.data.imgUrl.join(",");

          this.dynamicTags = res.data.data.label;
        }
      });
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.addFrom.label = this.dynamicTags.join(",");
      this.inputVisible = false;
      this.inputValue = "";
    },
    submitForm() {
      // console.log({...this.addFrom, label: this.dynamicTags.join(","),packDate:this.addFrom.packDate[0]+"-"+this.addFrom.packDate[1]});
      // return;
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return this.$message.warning('请完善信息')
        if (this.isSubmit) return;
        this.isSubmit = true;

        const { data } = await foodPackListAdd({
          ...this.addFrom,
          label: this.dynamicTags.join(","),
          packDate: this.addFrom.packDate[0] + "-" + this.addFrom.packDate[1],
        });
        this.isSubmit = false;
        if (data.code == 0) {
          this.goBack();
          this.$message.success(data.msg);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    addImageStr(e) {
      this.addFrom.showImg = e;
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
